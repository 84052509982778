<script>
    import { videos } from "../file_variables"
    import { YoutubeVideo } from "../components"
</script>

{#each videos as vid}
    <YoutubeVideo
        videoId={vid.videoId}
        title={vid.title}
        />
{/each}