<script>
    export let videoId
    export let title
</script>

<div class="bx--tracks-heading">
    {title}
</div>
<div class="bx--video-container">
    <iframe src={`https://www.youtube.com/embed/${videoId}`}
        frameborder="0" 
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen
        title={title}
    ></iframe>
</div>