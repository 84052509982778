const videos = [
  {
    title: "Side // Let It Go",
    videoId: "1hs5u2xQ4zc",
  },
  {
    title: "Comf - Water Demos",
    videoId: "DlJaLLfBHuI",
  },
];

export { videos };

/* 
Each video should be listed as follows in the above array 

  {
    title: "video title",
    videoId: "videos/video_name.extension",
  },

*/
