<script>
    import { ImageContainer } from "../components"
    import { images } from "../file_variables"

</script>

<div class="bx--tracks-heading">
    Gallery
</div>

<div class="bx--all-tracks">
    {#each images as image}
        <ImageContainer
            src={image.url}
            name={image.alt}
        />
    {/each}
</div>