<script>
    import { tweened } from "svelte/motion";
    import { cubicOut } from "svelte/easing";

    let position = tweened(1, {
        duration: 200,
        easing: cubicOut,
    });

    export let name
    export let src
    let expanded = false



    function expandImage() {
        expanded = !expanded
    }
</script>

<img class="bx--image-container"
    style="transform:scale({$position})"
    on:mouseenter={() => position.set(1.1)}
    on:mouseout={() => position.set(1)}
    on:blur={void 0}
    on:click={()=>expandImage()}
    src={src}
    alt={name}
    />

{#if expanded == true}
    <div class="bx--image-overlay" on:click={()=>expandImage()}>
        <img class="bx--overlaid-image" src={src} alt={name}/>
        <div class="bx--button">{name}</div>
    </div>
{/if}
