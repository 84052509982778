const images = [
  {
    alt: "Comf - Press Image 1",
    url: "images/1.jpg",
  },
  {
    alt: "Comf - Press Image 2",
    url: "images/2.jpg",
  },
  {
    alt: "Comf - Press Image 3",
    url: "images/3.jpg",
  },
];

export { images };

/* 
Each image should be listed as the following in above array 

  {
    alt: "image alt text",
    url: "images/imagename.extension",
  },

*/
