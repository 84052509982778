<script>
    import { tweened } from "svelte/motion";
    import { cubicOut } from "svelte/easing";

    let position = tweened(1, {
    duration: 200,
    easing: cubicOut,
  });

</script>

<div class="bx--button"
    on:click
    style="transform: scale({$position})"
    on:mouseenter={() => position.set(1.1)}
    on:mouseout={() => position.set(1)}
    on:blur={void 0}
>   
    <slot/>
</div>
