<script>
    export let currentPlayingTrack
    export let trackPlaying
    export let trackCheck
    let isTrackPlaying = false
    let audio = new Audio()
    let hovering = false

    function toggleHovering() {
		hovering = !hovering
	}

    function loadAndPlayTrack(currentPlayingTrack) {
        if (currentPlayingTrack == "") {
            audio.pause()
            audio = null
            isTrackPlaying = false
            hovering = false
        } else if (currentPlayingTrack == trackCheck) {
            console.log('passing')
        }
        else {
            if (isTrackPlaying == true) {
                audio.pause()
            }
            audio = new Audio(currentPlayingTrack.url)
            audio.play()
            isTrackPlaying = true
        }
    }

    function stopTrack() {
        currentPlayingTrack = ""
        trackPlaying = false
    }

    $:currentPlayingTrack, loadAndPlayTrack(currentPlayingTrack)
</script>

{#if trackPlaying == true}
    <div class="bx--audio-player" on:click={()=>stopTrack()} on:mouseenter={()=>toggleHovering()}
        on:mouseleave={()=>toggleHovering()}>
        {#if hovering == false}
            Now Playing: {currentPlayingTrack.name}
        {:else}
            StopStopStopStopStopStopStop
        {/if}
    </div>
{/if}