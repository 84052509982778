const tracks = [
  {
    name: "Flow",
    url: "tracks/1-flow-min.mp3",
  },
  {
    name: "Drop",
    url: "tracks/2-drop-min.mp3",
  },
  {
    name: "Side (Let It Go)",
    url: "tracks/3-side-min.mp3",
  },
  {
    name: "I Told You So",
    url: "tracks/4-told-min.mp3",
  },
  {
    name: "Do You",
    url: "tracks/5-do-min.mp3",
  },
  {
    name: "Test (Outro)",
    url: "tracks/6-test-min.mp3",
  },
];

export { tracks };

/*
Each track should be listed as follows in the above array 

  {
    name: "Track Name",
    url: "tracks/track_name.mp3",
  },

*/
