<script>
    import { downloads } from "../file_variables"
    import { DownloadButton } from "../components"
</script>

<div class="bx--tracks-heading">
    Downloads
</div>
<div class="bx--all-tracks">
    {#each downloads as download}
        <DownloadButton name={download.name} src={download.url}/>
    {/each}
</div>